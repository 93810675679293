<template>
  <div id="app">
    <router-view />
    <audio src="/static/tip.wav" id="audioTip" autoplay="false"></audio>
  </div>
</template>
<script>
export default {
  mounted() {
    document.getElementById("audioTip").play();
  },
};
</script>