<template>
  <el-container style="min-height: 100vh">
    <el-aside :width="sideWidth + 'px'" style="background-color: rgb(238, 241, 246);">
      <Aside :isCollapse="isCollapse" :LogoTextShow="LogoTextShow" style="padding-bottom: 20px" />
    </el-aside>

    <el-container>
      <el-header style=" border-bottom: 1px solid #ccc">
        <Header :collapseBtnClass="collapseBtnClass" @asideCollapse="collapse" :user="user" />
        <!-- :collapse="isCollapse" -->
      </el-header>

      <el-main>
        <router-view @refreshUser="getUser" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// import { request } from 'http'
// import { log } from 'console'
// import request from '@/utils/request'
import Aside from '../components/Aside.vue'
import Header from '../components/Header.vue'

export default {
  name: 'Home',

  data() {
    return {
      collapseBtnClass: 'el-icon-s-fold',
      isCollapse: false,
      sideWidth: 200,
      LogoTextShow: true,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },

  created() {
  },
  components: {
    Aside,
    Header,
  },
  methods: {
    collapse() {
      this.isCollapse = !this.isCollapse
      if (this.isCollapse) {
        this.sideWidth = 64
        this.collapseBtnClass = 'el-icon-s-unfold'
        this.LogoTextShow = false
      } else {
        this.sideWidth = 200
        this.collapseBtnClass = 'el-icon-s-fold'
        this.LogoTextShow = true
      }
    },

    getUser() {
      this.request.get("http://localhost:9090/user/" + this.user.userName).then(res => {
        this.user = res.data
      })
    },
  },
}
</script>

